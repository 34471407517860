import React from 'react';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import ProgressButton from '../System/ProgressButton';
import useAbsenceForm from './useAbsenceForm';
import MomentSelect from '../System/MomentSelect';
import FieldsContainer from '../System/FieldsContainer';
import PrescriptionSelect from '../System/PrescriptionSelect';
import SelectDatePicker from '../System/SelectDatePicker';

const useStyles = makeStyles((theme) => ({
  form: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
  fieldset: {
    padding: theme.spacing(2),
  },
  unknownEnd: {
    marginBottom: theme.spacing(1),
  },
  submitContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  datePicker: {
    marginTop: '8px',
  },
  patientName: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1rem',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '& sup': {
        position: 'relative',
        '& svg': {
          position: 'absolute',
          width: 12,
          marginLeft: theme.spacing(0.5),
        },
      },
    },
  },
}));

const AbsenceForm = ({ initialAbsence, type, editMode, onSubmitted }) => {
  const classes = useStyles();

  const {
    loading,
    error,
    generalInputs,
    unknownEnd,
    onGeneralInputChange,
    onUnknownEndChange,
    onSubmit,
  } = useAbsenceForm({ initialAbsence, type, onSubmitted });

  const [startDate, setStartDate] = React.useState(generalInputs.startDate);
  const [endDate, setEndDate] = React.useState(
    moment.utc(Number(generalInputs.endDate)).startOf('day').add(12, 'hours'),
  );

  return (
    <div className={classes.form}>
      <div className={classes.inputsContainer}>
        <fieldset className={classes.fieldset} disabled={!editMode}>
          <FieldsContainer>
            <Typography className={classes.patientName}>
              Patient :{' '}
              <strong>
                {initialAbsence?.patient.firstName}{' '}
                {initialAbsence?.patient.lastName.toUpperCase()}
              </strong>
            </Typography>
          </FieldsContainer>
          <FieldsContainer>
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item className={classes.datePicker}>
                <SelectDatePicker
                  disableToolbar={false}
                  label="Début de l'absence (inclus)"
                  value={startDate}
                  onChange={(date) => {
                    onGeneralInputChange({
                      target: {
                        name: 'startDate',
                        value: Number(date.valueOf()),
                      },
                    });
                    setStartDate(date);
                  }}
                  readOnly={!editMode}
                />
              </Grid>
              <Grid item xs>
                <MomentSelect
                  name="startMoment"
                  size="small"
                  value={generalInputs.startMoment}
                  onChange={onGeneralInputChange}
                />
              </Grid>
            </Grid>
            {error?.message ? (
              <Typography color="error">
                {error.message === 'min date should be lower than max date'
                  ? 'La date de début ne peut être postérieure à la date de fin'
                  : error.message === 'min date should not be older then 5 days'
                  ? 'La date de début ne peut être de plus de 5 jours dans le passé'
                  : error.message}
              </Typography>
            ) : null}
          </FieldsContainer>
          <FieldsContainer>
            <div className={classes.unknownEnd}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!editMode}
                    checked={unknownEnd}
                    color="primary"
                    onChange={onUnknownEndChange}
                  />
                }
                label="Date inconnue"
              />
            </div>

            {unknownEnd ? null : (
              <Grid container justifyContent="flex-start" spacing={1}>
                <Grid item className={classes.datePicker}>
                  <SelectDatePicker
                    disableToolbar={false}
                    label="Fin de l'absence (incluse)"
                    value={endDate}
                    onChange={(date) => {
                      onGeneralInputChange({
                        target: {
                          name: 'endDate',
                          value: Number(date.valueOf()),
                        },
                      });
                      setEndDate(date);
                    }}
                    readOnly={!editMode}
                  />
                </Grid>
                <Grid item xs>
                  <MomentSelect
                    name="endMoment"
                    size="small"
                    value={generalInputs.endMoment}
                    onChange={onGeneralInputChange}
                  />
                </Grid>
              </Grid>
            )}
          </FieldsContainer>

          <PrescriptionSelect
            disabled={!editMode}
            name="prescriptions"
            patient={initialAbsence.patient}
            selected={generalInputs.prescriptions}
            onChange={onGeneralInputChange}
          />
        </fieldset>
      </div>

      {editMode ? (
        <div className={classes.submitContainer}>
          <ProgressButton
            disabled={error}
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {type === 'update' ? 'Sauvegarder' : 'Créer'}
          </ProgressButton>
        </div>
      ) : null}
    </div>
  );
};

export default AbsenceForm;
